@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


* {
  box-sizing: border-box;
  margin: auto;
 
}


@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "../node_modules/bootstrap/scss/_functions";
@import "../node_modules/bootstrap/scss/_variables";
@import "../node_modules/bootstrap/scss/_variables-dark";

body {
  font-family: 'Lato', sans-serif;
  background-color: #ffffff;
  color: #212529;
  overflow-x: hidden;
  margin: auto;
}

.main {
  min-width: 350px;
}


.mainbox {
  margin: 0 70px;
}

.MuiAxis-label {
  font-size: 0.2rem  ; /* Adjust font size */
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

// .sticky-navbar {
//   position: sticky;
//   top: 0; /* Sticks the navbar to the top of the viewport */
//   z-index: 1000; /* Ensures it stays above other elements */
//   background-color: white; /* Optional: Background color to prevent overlap issues */
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
// }

.footer-link {
  color: #212529 !important;
  text-decoration: underline;
}

.footer-link:hover {
  color: #212529;
  text-decoration: none;
}


.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .275rem .50rem;
  line-height: 1.3;
  border-radius: 50rem;
  transition: color .15s, background-color .15s, border-color .15s, box-shadow .15s;
  margin: 4px
 
 
}

.btn-primary {
  background-color: #997dff; 
  border-radius: 50rem; 
  border-color:  #997dff; 
  color: #ffffff; 
  font-size:1rem;
  
}

.btn-outline-primary {
  background-color: #ffffff  ;
  border-radius: 50rem  ;
  border-color:  #997dff  ;
  color: #997dff  ;
  font-size: 1rem;
}


.btn-secondary {
  background-color: #85BFB6 ;
  border-radius: 50rem  ;
  border-color:  #85BFB6  ;
  color: #212529  ;
  font-size: 1rem;
}

.btn-outline-secondary {
  background-color: #ffffff  ;
  border-radius: 50rem  ;
  border-color:  #85BFB6  ;
  color: #85BFB6  ;
  font-size: 1rem;
}

.btn-third {
  background-color: #BE97C6 ;
  border-radius: 50rem  ;
  border-color:  #BE97C6  ;
  color: #212529  ;
  font-size: 1rem;
}

.btn-fourth {
  background-color: #528A8F ;
  border-radius: 50rem  ;
  border-color:  #528A8F  ;
  color: #ffffff  ;
  font-size: 1rem;
}
.btn-black-purple {
  background-color: #212529  ;
  border-radius: 50rem  ;
  border-color:  #e4e5fa  ;
  color: #e4e5fa  ;
  font-size: 1rem;
}

.btn-black-green {
  background-color: #212529  ;
  border-radius: 50rem  ;
  border-color:  #85BFB6  ;
  color: #85BFB6  ;
  font-size: 1rem;
}
.btn-black-white {
  background-color: #212529  ;
  border-radius: 50rem  ;
  border-color:  #ffffff  ;
  color: #ffffff  ;
  font-size: 1rem;
}

.btn:hover {
  background-color: #ffffff  ;
  border-radius: 50rem  ;
  border-color:  #212529  ;
  color: #212529  ;
  
 
  }

.btn-small-tech  {
    background-color: #212529  ;
    border: 1px;
    border-color: #212529;
    border-radius: 50rem  ;
    color: #ffffff  ;
    font-size: 0.7rem;
    margin: 3px;
    padding: .2rem .4rem;
    width: auto;
    pointer-events: none;
  }



  .custom-nav-buttons {
    display: flex;
    font-size: 1rem;
    margin: 3px;
   }

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.special-purple {
  font-family: Playfair Display;
  font-style: italic;
  color: #997dff;
}

.special-black {
  font-family: Playfair Display;
  font-style: italic;
  color: #212529;
}

/* Remove the border around the button */
.navbar-toggler {
  background-color: #f3eefa;
  color: #997dff ;
}

/* Change the color of the hamburger lines */
.navbar-toggler-icon {
  color: #997dff ;
  transition: all 0.3s ease;
}

@media (min-width: 769px) and (max-width: 1200px) {

  * {
    box-sizing: border-box;
    margin: auto;
   
  }


.mainbox {
  margin: 0 5px;
}


.btn-primary, .btn-outline-primary, .btn-secondary, .btn-outline-secondary, .btn-black-purple, .btn-black-green, .btn-fourth, .btn-third, .btn:hover {
  margin: auto;
  align-self: center;
  margin-bottom: 1rem; /* Add vertical spacing */
  gap: 0.5rem; /* Add vertical spacing */
  font-size: 0.8rem;
}

.custom-nav-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3px;
  align-self: center;
  margin-bottom: 0.2rem; /* Add vertical spacing */
  gap: 0.5rem; /* Add vertical spacing */
  font-size: 1rem;
 }

}

@media (min-width: 501px) and (max-width: 768px) {


  .mainbox {
    margin: 0 5px;
  }



/* Add width and margin adjustments for buttons */
.btn-primary, .btn-outline-primary, .btn-secondary, .btn-outline-secondary, .btn-black-purple, .btn-black-green, .btn-fourth, .btn-third, .btn:hover {
  margin: auto;
  align-self: center;
  margin-bottom: 1rem; /* Add vertical spacing */
  gap: 0.5rem; /* Add vertical spacing */
  font-size: 0.8rem;
}

.custom-nav-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  align-self: center;
  gap: 0.5rem; /* Add vertical spacing */
  font-size: 1rem;
}

/* Center-align toggle switches */
.custom-toggle-switches {
  justify-content: center;
  width: 100%;
 }

 .col {
  flex: 0 0 100%;
  max-width: 100%;
}
}

/* Style for small screens */
@media (min-width: 0px)  and (max-width: 500px) {

  .mainbox {
    margin: 0 5px;
    min-width: 100%;
  }

  .btn-primary, .btn-outline-primary, .btn-secondary, .btn-outline-secondary, .btn-black-purple, .btn-black-green, .btn-fourth, .btn-third, .btn:hover {
    margin: auto;
    align-self: center;
    margin-bottom: 1rem; /* Add vertical spacing */
    gap: 0.4rem; /* Add vertical spacing */
    font-size: 0.8rem;
  }

  .custom-nav-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    align-self: center;
    gap: 0.5rem; /* Add vertical spacing */
    font-size: 1rem;
   }

  /* Center-align toggle switches */
  .custom-toggle-switches {
    justify-content: center;
    width: 100%;
   }

}

